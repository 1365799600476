import Glide, {
  Breakpoints,
  Controls,
  Swipe,
} from "@glidejs/glide/dist/glide.modular.esm";
import Cookies from "js-cookie";
import Dropdown from "bootstrap/js/dist/dropdown"; // eslint-disable-line no-unused-vars

import {
  initClipboard,
  initTooltips,
  isInViewport,
  scrollToElement,
  sendBrowserAgnosticEvent,
} from "../core/utils";
import initOrganizationSelect from "../components/OrganizationSelect";
import { initProductPhotoBlur } from "../commerce/utils";
import ProgressRing from "../components/ProgressRing";
import { initAddFuneralHomeWarning } from "../memorials/utils";
import { initCustomerGuidance } from "../places/places";

const TOUR_DELAY = 2000;

/**
 * Initialize the employee dashboard tour.
 *
 * @param {props} props - the props for the user dashboard page
 */
function initBusinessDashboardTour(props) {
  // Instead of relying on anything else here let's just cut off the tour from being
  // accessible at narrower than 1000 pixels wide. That's when the layout changes,
  // more or less.
  if (window.innerWidth < 800) {
    return;
  }

  if (!props) {
    return;
  }

  const { businessType, businessSlug, tourData } = props;

  let [currentTourStep] = tourData;

  const container = document.querySelector(".tour-container");

  if (!container) {
    return;
  }

  const businessCard = document.querySelector(`#${businessType}-${businessSlug}`);

  if (!businessCard) {
    return;
  }

  let tourHasBeenInvoked = false;
  let advanceTour;

  const closeTour = () => {
    document.querySelectorAll(".tour-target").forEach((target) => {
      target.classList.remove("tour-target");
    });

    document.querySelectorAll(".tour-step").forEach((step) => {
      step.classList.add("d-none");
    });

    container.classList.add("d-none");
  };

  const renderCurrentTourStep = () => {
    document.querySelectorAll(".tour-step").forEach((step) => {
      step.classList.add("d-none");
      const tourStepTarget = document.querySelector(
        `[data-tour-target-for="${step.id}"]`,
      );
      if (tourStepTarget) {
        tourStepTarget.classList.toggle("tour-target", step.id === currentTourStep.id);
      }
    });

    const currentStep = document.getElementById(currentTourStep.id);

    if (currentStep) {
      if (currentTourStep.alone) {
        currentStep.style.position = "fixed";
        currentStep.style.width = "33%";
        currentStep.style.left = "33%";
        currentStep.style.top = "25%";
        currentStep.classList.remove("d-none");
      } else {
        const tourStepTarget = document.querySelector(
          `[data-tour-target-for="${currentStep.id}"]`,
        );
        if (tourStepTarget) {
          if (!isInViewport(tourStepTarget)) {
            tourStepTarget.scrollIntoView(true);
          }

          // Do the following in a 100ms timeout because `scrollIntView` doesn't
          // synchronously update the bounding client rect we need.
          setTimeout(() => {
            const targetRect = tourStepTarget.getBoundingClientRect();

            const leftEdge = targetRect.left;
            const rightEdge = targetRect.left + targetRect.width;
            const topEdge = targetRect.top;
            const bottomEdge = targetRect.top + targetRect.height;

            const isFloatMode =
              window.innerWidth - targetRect.width < 300 &&
              window.innerHeight - targetRect.height < 300;

            // Reset positioning to null state.
            currentStep.style.position = "fixed";
            currentStep.style.left = null;
            currentStep.style.right = null;
            currentStep.style.top = null;
            currentStep.style.bottom = null;

            let horizontalSpace;
            let horizontalAnchor;
            let verticalSpace;
            let verticalAnchor;

            if (isFloatMode) {
              currentStep.style.setProperty("top", "20px");
              currentStep.style.setProperty("right", "20px");
              currentStep.dataset.nonFloatZIndex = currentStep.style.zIndex;
              currentStep.style.setProperty("z-index", "1060");
            } else {
              if (currentStep.dataset.nonFloatZIndex) {
                currentStep.style.setProperty(currentStep.dataset.nonFloatZIndex);
                delete currentStep.dataset.nonFloatZIndex;
              }

              // Determine if we're placing above or below target.
              if (topEdge > window.innerHeight - bottomEdge) {
                // More space on top.
                verticalSpace = window.innerHeight - topEdge;
                verticalAnchor = "bottom";
              } else {
                // More space on bottom.
                verticalSpace = bottomEdge;
                verticalAnchor = "top";
              }

              // Determine if we're placing left or right of target.
              if (leftEdge > window.innerWidth - rightEdge) {
                // More space to left.
                horizontalSpace = window.innerWidth - leftEdge;
                horizontalAnchor = "right";
              } else {
                // More space to right.
                horizontalSpace = rightEdge;
                horizontalAnchor = "left";
              }

              // Determine if we're placing beside or above/below.
              if (horizontalSpace > verticalSpace) {
                // Left/right
                currentStep.style.setProperty(horizontalAnchor, `${horizontalSpace}px`);
                currentStep.style.top = `${topEdge}px`;
              } else {
                // Top/bottom
                currentStep.style.setProperty(verticalAnchor, `${verticalSpace}px`);
                currentStep.style.left = `${leftEdge}px`;
              }
            }

            currentStep.classList.remove("d-none");

            if (!isFloatMode) {
              // Correct flowing over bottom
              if (
                window.innerHeight - currentStep.getBoundingClientRect().bottom <
                60
              ) {
                currentStep.style.top = null;
                currentStep.style.bottom = "0";
              }
            }
          }, 300);
        } else {
          advanceTour();
        }
      }
    }
  };

  advanceTour = () => {
    const currentIndex = tourData.indexOf(currentTourStep);
    if (currentIndex + 1 >= tourData.length) {
      // We're at the end!
      [currentTourStep] = tourData;
      closeTour();
    } else {
      currentTourStep = tourData[currentIndex + 1];
      renderCurrentTourStep();
    }
  };

  const beginTour = () => {
    container.classList.remove("d-none");
    renderCurrentTourStep();
    tourHasBeenInvoked = true;
  };

  document.querySelectorAll(".tour-next").forEach((nextBtn) => {
    nextBtn.addEventListener("click", () => {
      advanceTour();
    });
  });

  document.querySelectorAll(".tour-exit").forEach((exitBtn) => {
    exitBtn.addEventListener("click", () => {
      closeTour();
    });
  });

  // Initial timeout
  const timerFire = () => {
    if (!tourHasBeenInvoked) {
      beginTour();
    }
  };

  let timeoutId = setTimeout(timerFire, TOUR_DELAY);

  document.addEventListener("mousemove", () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }

    if (!tourHasBeenInvoked) {
      timeoutId = setTimeout(timerFire, TOUR_DELAY);
    }
  });

  // Don't let the timer fire while page is backgrounded.
  // Restart it when we're foregrounded if applicable.
  document.addEventListener("visibilitychange", () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }

    if (!document.hidden && !tourHasBeenInvoked) {
      setTimeout(timerFire, TOUR_DELAY);
    }
  });
}

/**
 * Run all code for the user dashboard page.
 *
 * @param {props} props - the props for the user dashboard page
 */
export default function initDashboard(props) {
  let offset = -20;
  if (window.isOnMobile) {
    offset = 0;
  }

  if (props.productListingChangedFor) {
    scrollToElement(
      document.querySelector(`[data-product-slug='${props.productListingChangedFor}'`),
      offset,
    );
  }

  if (props.shouldUseAutocomplete) {
    const addEmployeeProfileButton = document.querySelector(
      ".employee-profile-add-button a",
    );
    addEmployeeProfileButton.addEventListener("click", (event) => {
      document.querySelector(".business-select").classList.remove("d-none");
      document.querySelector(".add-employee-profile-form").classList.remove("d-none");
      event.currentTarget.parentElement.classList.add("d-none");
    });

    initOrganizationSelect(props);

    if (props.businessTypeOpen) {
      sendBrowserAgnosticEvent(addEmployeeProfileButton, "click");
    }
  }

  initClipboard();
  initProductPhotoBlur();
  initCustomerGuidance(props);
  initBusinessDashboardTour(props.tourProps);

  document
    .querySelectorAll(".dashboard-memorial-checklist-progress")
    .forEach((progressContainer) => {
      const { percentage } = progressContainer.dataset;
      if (percentage) {
        new ProgressRing(progressContainer, percentage);
      }
    });

  document
    .querySelectorAll(".help-your-family-body-card .toggle-user-preference")
    .forEach((toggler) => {
      toggler.addEventListener("click", () => {
        const card = toggler.closest(".card");
        card.classList.toggle("complete", !card.classList.contains("complete"));
      });
    });

  // Add CSRF to HTMX requests
  document.body.addEventListener("htmx:configRequest", (event) => {
    event.detail.headers["X-CSRFToken"] = Cookies.get("csrftoken");
  });

  const helpYourFamilyContainer = document.querySelector(".help-your-family-body-card");
  if (helpYourFamilyContainer) {
    new Glide(helpYourFamilyContainer.querySelector(".glide"), {
      animationDuration: 600,
      gap: 20,
      peek: { before: 0, after: 342 },
      rewind: false,
      // Values for `after` roughly calculated as (<body card width> * .385) - <body card padding>
      // Breakpoints use max-width and the balues are taken from Bootstrap
      breakpoints: {
        1200: { peek: { before: 0, after: 290 } },
        992: { peek: { before: 0, after: 198 } },
        768: { peek: { before: 0, after: 50 } },
      },
    }).mount({ Breakpoints, Controls, Swipe });
  }

  initTooltips();
  initAddFuneralHomeWarning();
}
