import ClipboardJS from "clipboard";

import { initEditDropdowns } from "../components/EditMenuDropdowns";
import initSuccessModal from "../components/SuccessModal";
import { initEditableUserPosts } from "../components/UserPost";
import { scrollToElement } from "../core/utils";

/**
 * Run the code for a discussion page.
 *
 * @param {object} props - the props for a discussion page
 */
export function initDiscussion(props) {
  if (props.scrollTo) {
    const element = document.querySelector(props.scrollTo);
    if (element) {
      scrollToElement(element, -140);
      if (element.classList.contains("post")) {
        element.classList.add("highlighted");
      }
    }
  }

  const quotedPostField = document.querySelector(`#${props.quotedPostFieldId}`);
  const replyingTo = document.querySelector(".replying-to");
  replyingTo.querySelector(".cancel-reply").addEventListener("click", () => {
    quotedPostField.value = null;
    replyingTo.classList.add("d-none");
  });

  const createPostHandler = (event) => {
    const quotedPostId = event.target.getAttribute("data-quote-post");
    if (quotedPostId) {
      quotedPostField.value = quotedPostId;
      replyingTo.classList.remove("d-none");
      replyingTo.querySelector("span").innerHTML =
        `Replying to <strong>${event.target.getAttribute("data-quote-poster")}</strong>`;
    } else {
      quotedPostField.value = null;
      replyingTo.classList.add("d-none");
    }

    const postInput = document.querySelector(".reply-form .post-input > textarea");
    scrollToElement(postInput, -140);
    postInput.focus();
  };

  document.querySelectorAll(".create-post-link").forEach((link) => {
    link.addEventListener("click", createPostHandler);
  });

  const clipboard = new ClipboardJS(".copy-post-link");
  clipboard.on("success", (event) => {
    event.trigger.innerText = "Copied!";
  });

  initEditableUserPosts(props);
  initEditDropdowns();
}

/**
 * Run the code for a discussion category page.
 */
export function initDiscussionCategory() {
  document.querySelectorAll(".compose-discussion-link").forEach((link) => {
    link.addEventListener("click", () => {
      const composeDiscussionBody = document.querySelector(".compose-post");
      composeDiscussionBody.classList.remove("d-none");
      link.classList.add("d-none");
      composeDiscussionBody.querySelector('input[type="text"]').focus();
      scrollToElement(composeDiscussionBody, -40);
    });
  });

  initSuccessModal();
}

/**
 * Run the code for the discussion compose page.
 */
export function initComposeDiscussion() {
  scrollToElement(document.querySelector(".discussions-body"), -60);
}
