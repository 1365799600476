import Dropdown from "bootstrap/js/dist/dropdown"; // eslint-disable-line no-unused-vars
import Modal from "bootstrap/js/dist/modal";
import Tooltip from "bootstrap/js/dist/tooltip";
import { storageAvailable } from "../core/utils";

function verifyAccept(type, accept) {
  const allowed = accept.split(",").map((x) => x.trim());
  return allowed.includes(type) || allowed.includes(`${type.split("/")[0]}/*`);
}

export default function initConversations() {
  let conversationScrollPosition = null;

  document.body.addEventListener("htmx:beforeRequest", (evt) => {
    if (evt.detail.elt.id === "compose-message") {
      const textField = evt.detail.elt.querySelector("textarea");
      const attachmentField = evt.detail.elt.querySelector('input[type="file"]');
      if (
        !(textField && textField.value) &&
        !(attachmentField && attachmentField.files.length > 0)
      ) {
        evt.preventDefault();
        return;
      }

      evt.target.setAttribute("disabled", "disabled");
      evt.target.querySelectorAll('[type="submit"]').forEach((submitButton) => {
        submitButton.setAttribute("disabled", "disabled");
        submitButton.classList.add("disabled");
      });
      evt.detail.elt.querySelectorAll("textarea").forEach((textarea) => {
        textarea.setAttribute("readonly", "readonly");
      });
    }
  });

  ["htmx:sendError", "htmx:responseErr"].forEach((err) => {
    document.body.addEventListener(err, (evt) => {
      if (evt.detail.elt.id === "compose-message") {
        evt.detail.elt.removeAttribute("disabled");
        evt.detail.elt.querySelectorAll('[type="submit"]').forEach((submitButton) => {
          submitButton.removeAttribute("disabled");
          submitButton.classList.remove("disabled");
        });
        evt.detail.elt.querySelectorAll("textarea").forEach((textarea) => {
          textarea.removeAttribute("readonly");
        });
      }
    });
  });

  document.body.addEventListener("htmx:oobBeforeSwap", (evt) => {
    if (evt.target.classList.contains("dialogue-container")) {
      const dialogueContainer = document.querySelector(".dialogue-container");
      const dialogue = document.querySelector(".dialogue");

      if (dialogueContainer && dialogue) {
        conversationScrollPosition = dialogueContainer.scrollTop - dialogue.scrollTop;
        if (
          dialogue.scrollHeight -
            (conversationScrollPosition + dialogueContainer.clientHeight) <
          100
        ) {
          conversationScrollPosition = -1;
        }
      }
    }
  });

  document.body.addEventListener("htmx:oobAfterSwap", (evt) => {
    if (evt.target.classList.contains("dialogue-container")) {
      const dialogueContainer = document.querySelector(".dialogue-container");
      const dialogue = document.querySelector(".dialogue");

      if (dialogueContainer && dialogue) {
        if (conversationScrollPosition === -1) {
          dialogueContainer.scroll(0, dialogue.scrollHeight);
        } else if (conversationScrollPosition) {
          dialogueContainer.scroll(0, conversationScrollPosition);
        }

        conversationScrollPosition = null;
      }
    }
  });

  // Scroll to the end of the conversation.
  const scrollToConversationEnd = () => {
    const dialogueContainer = document.querySelector(".dialogue-container");
    if (dialogueContainer) {
      dialogueContainer.scrollTop =
        dialogueContainer.scrollHeight - dialogueContainer.clientHeight;
    }
  };

  scrollToConversationEnd();

  const tooltipSpawn = document.querySelector('[data-bs-also-toggle="tooltip"]');
  if (tooltipSpawn && !document.querySelector(".dialogue-container")) {
    if (storageAvailable("localStorage")) {
      const lastShownHint = window.localStorage.getItem("el:messages-business-hint");
      const title = tooltipSpawn.getAttribute("title");

      if (lastShownHint !== title) {
        const tooltip = new Tooltip(tooltipSpawn);

        setTimeout(() => {
          tooltip.show();
          window.localStorage.setItem("el:messages-business-hint", title);
        }, 1500);

        tooltipSpawn.addEventListener("hidden.bs.tooltip", () => {
          tooltip.disable();
        });
      }
    }
  }

  const initAttachmentBehavior = () => {
    const addAttachmentButton = document.querySelector(".add-attachment");
    const attachmentInput = document.querySelector('[name="attachment"]');
    const attachmentDataContainer = document.querySelector(".attachment-data");
    const removeAttachmentButton = document.querySelector(".remove-attachment");
    const dragTarget = document.querySelector(".inputs-container");

    if (
      addAttachmentButton &&
      attachmentInput &&
      attachmentDataContainer &&
      removeAttachmentButton
    ) {
      addAttachmentButton.addEventListener("click", () => {
        attachmentInput.click();
      });

      attachmentInput.addEventListener("change", () => {
        if (attachmentInput.files.length) {
          const file = attachmentInput.files[0];
          if (verifyAccept(file.type, attachmentInput.getAttribute("accept"))) {
            attachmentDataContainer.querySelector(".attachment-name").innerText =
              file.name;
          } else {
            attachmentInput.value = null;
            if (dragTarget) {
              dragTarget.classList.add("file-type-error");
              setTimeout(() => {
                dragTarget.classList.remove("file-type-error");
              }, 750);
            }
          }
        }

        attachmentDataContainer.classList.toggle(
          "d-none",
          !attachmentInput.files.length,
        );
        addAttachmentButton.classList.toggle("d-none", attachmentInput.files.length);
      });

      removeAttachmentButton.addEventListener("click", () => {
        attachmentInput.value = null;
        attachmentDataContainer.classList.add("d-none");
        addAttachmentButton.classList.remove("d-none");
      });
    }

    if (dragTarget && attachmentInput) {
      [
        "drag",
        "dragstart",
        "dragend",
        "dragover",
        "dragenter",
        "dragleave",
        "drop",
      ].forEach((eventName) => {
        dragTarget.addEventListener(eventName, (event) => {
          event.stopPropagation();
          event.preventDefault();

          if (eventName === "drop") {
            const allValid = Array.from(event.dataTransfer.files).every((file) =>
              verifyAccept(file.type, attachmentInput.getAttribute("accept")),
            );

            if (allValid) {
              attachmentInput.files = event.dataTransfer.files;
              if (attachmentInput.files.length) {
                const file = attachmentInput.files[0];
                attachmentDataContainer.querySelector(".attachment-name").innerText =
                  file.name;
              }

              attachmentDataContainer.classList.toggle(
                "d-none",
                !attachmentInput.files.length,
              );
              addAttachmentButton.classList.toggle(
                "d-none",
                attachmentInput.files.length,
              );
            } else {
              dragTarget.classList.add("file-type-error");
              setTimeout(() => {
                dragTarget.classList.remove("file-type-error");
              }, 750);
            }
            dragTarget.classList.remove("dragging");
          }
        });
      });

      document.body.addEventListener("dragenter", () => {
        dragTarget.classList.add("dragging");
      });

      document.body.addEventListener("dragend", (evt) => {
        dragTarget.classList.remove("dragging");

        if (
          evt.dataTransfer &&
          evt.dataTransfer.files &&
          evt.dataTransfer.files.length
        ) {
          evt.stopPropagation();
          evt.preventDefault();
        }
      });
    }
  };

  initAttachmentBehavior();

  document.body.addEventListener("htmx:afterSwap", (evt) => {
    if (evt.target.classList.contains("conversation-content")) {
      initAttachmentBehavior();
    }
  });

  document.body.addEventListener("invalidAttachment", () => {
    const dragTarget = document.querySelector(".inputs-container");
    if (dragTarget) {
      dragTarget.classList.add("file-type-error");
      setTimeout(() => {
        dragTarget.classList.remove("file-type-error");
      }, 750);
    }
  });

  document.body.addEventListener("warnCallRequest", (evt) => {
    const nameSpan = document.querySelector(
      "#call-request-warning-modal .recipient-name",
    );
    if (nameSpan) {
      nameSpan.innerText = evt.detail.value;
      Modal.getOrCreateInstance("#call-request-warning-modal").show();
    }
  });

  const messageInput = document.querySelector("#message-input");
  if (messageInput) {
    if (messageInput.value && !messageInput.getAttribute("disabled")) {
      messageInput.focus();
    }
  }

  const prefillWarning = document.getElementById("prefilled-message-modal");
  if (prefillWarning) {
    Modal.getOrCreateInstance(prefillWarning).show();
  }
}
