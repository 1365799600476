export default function initDonationHistory() {
  const form = document.querySelector("form.edit-contributions");
  const saveChangesBar = document.querySelector(".save-changes-bar");
  const events = ["change", "input"];
  form.querySelectorAll("input").forEach((input) => {
    events.forEach((evt) => {
      input.addEventListener(evt, () => {
        saveChangesBar.classList.add("show");
      });
    });
  });

  document.querySelectorAll(".toggle-edit-on-behalf-of").forEach((toggler) => {
    toggler.addEventListener("click", () => {
      const cell = toggler.closest("td");
      cell.querySelector(".display-name-form-field").classList.remove("d-none");
      cell.querySelector(".display-name-form-field input").focus();
      cell.querySelector(".display-name-form-field input").setSelectionRange(0, 1000);
      cell.querySelector(".static-on-behalf").classList.add("d-none");
      cell.querySelector(".display-name-on-behalf").classList.remove("d-none");
      cell.querySelector(".anonymous-on-behalf").classList.add("d-none");
      toggler.classList.add("d-none");
      toggler.closest("tr").querySelector('.anonymous input[type="checkbox"]').checked =
        false;
    });
  });

  document.querySelectorAll('.anonymous input[type="checkbox"]').forEach((anon) => {
    anon.addEventListener("change", () => {
      const onBehalfCell = anon.closest("tr").querySelector(".on-behalf-of");
      if (anon.checked) {
        onBehalfCell.querySelector(".display-name-form-field").classList.add("d-none");
        onBehalfCell.querySelector(".static-on-behalf").classList.remove("d-none");
        onBehalfCell.querySelector("a").classList.remove("d-none");

        onBehalfCell.querySelector(".display-name-on-behalf").classList.add("d-none");
        onBehalfCell.querySelector(".anonymous-on-behalf").classList.remove("d-none");
      } else {
        onBehalfCell
          .querySelector(".display-name-on-behalf")
          .classList.remove("d-none");
        onBehalfCell.querySelector(".anonymous-on-behalf").classList.add("d-none");
      }
    });
  });
}
