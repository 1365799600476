export default function initDonationList(props) {
  const startSelect = document.querySelector("select.start");
  const endSelect = document.querySelector("select.end");

  const selectedStartIndex = startSelect.selectedIndex;
  const selectedEndIndex = endSelect.selectedIndex;

  startSelect.querySelectorAll("option").forEach((opt, idx) => {
    opt.disabled = idx > selectedEndIndex;
  });

  endSelect.querySelectorAll("option").forEach((opt, idx) => {
    opt.disabled = idx < selectedStartIndex;
  });

  const handleSelectionChange = () => {
    const selectedStart = startSelect.selectedOptions[0].value;
    const selectedEnd = endSelect.selectedOptions[0].value;
    window.location.href = `${props.baseUrl}?start=${selectedStart}&end=${selectedEnd}`;
  };

  startSelect.addEventListener("change", handleSelectionChange);
  endSelect.addEventListener("change", handleSelectionChange);

  const downloadCsvLink = document.querySelector(".download-link");
  downloadCsvLink.addEventListener("click", () => {
    const selectedStart = startSelect.selectedOptions[0].value;
    const selectedEnd = endSelect.selectedOptions[0].value;
    window.location.href = `${props.baseUrl}?start=${selectedStart}&end=${selectedEnd}&download=csv`;
  });
}
