import { appendSimpleChildElement } from "../core/utils";

/**
 * Return true if every checkbox in the input node list is unchecked.
 *
 * @param {NodeList<HTMLInputElement>} checkboxes - the checkboxes
 * @returns {boolean} true if all checkboxes are unchecked
 */
const allFalse = (checkboxes) =>
  Array.from(checkboxes).every((v) => v.checked === false);

/**
 * Initialize the email subscription settings page behaviors. Checkbox wrangling, mainly.
 */
export default function initEmailSubscriptionSettings() {
  const categoryCheckboxes = document.querySelectorAll(".category-checkbox input");
  categoryCheckboxes.forEach((categoryCheckbox) => {
    const groupCheckboxes = categoryCheckbox
      .closest(".el-input-group")
      .querySelectorAll(".email-groups input");
    categoryCheckbox.addEventListener("change", () => {
      if (!categoryCheckbox.checked) {
        groupCheckboxes.forEach((groupCheckbox) => {
          groupCheckbox.checked = false;
        });
      } else {
        groupCheckboxes.forEach((groupCheckbox) => {
          groupCheckbox.checked = true;
        });
      }
    });

    groupCheckboxes.forEach((groupCheckbox) => {
      groupCheckbox.addEventListener("change", () => {
        if (groupCheckbox.checked && !categoryCheckbox.checked) {
          categoryCheckbox.checked = true;
        } else if (!groupCheckbox.checked && allFalse(groupCheckboxes)) {
          categoryCheckbox.checked = false;
        }
      });
    });
  });

  document.querySelector(".unsubscribe-all").addEventListener("click", () => {
    document.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
      checkbox.checked = false;
    });

    const form = document.querySelector(".email-subscription-settings-form");
    appendSimpleChildElement(form, "input", {
      type: "hidden",
      name: "unsubscribe-all",
      value: 1,
    });
    form.requestSubmit();
  });
}
