import ScrollSpy from "bootstrap/js/dist/scrollspy";

import initGoogleAutocomplete from "../components/GoogleAutocomplete";
import initPhotoCropper from "../components/PhotoCropper";
import initPublicAutoScroll from "../components/PublicAutoScroll";
import config from "../core/config";
import {
  initClipboard,
  registerFormSubmitProtection,
  scrollToElement,
} from "../core/utils";

/**
 * Run the code for the user profile page.
 *
 * @param {object} props - the props for the user profile page
 */
export function initUserProfile(props) {
  new ScrollSpy(document.body, {
    rootMargin: "-10% 0px -70%",
    smoothScroll: true,
    target: "#member-profile-nav",
    threshold: [0],
  });

  if (props.isEditable) {
    const openEditHandler = (event, formId, formHtml) => {
      const existingForm =
        document.getElementById(props.relationshipFormId) ||
        document.getElementById(props.managerAliasFormId);

      if (existingForm) {
        if (existingForm.id === formId) {
          if (
            event.currentTarget.closest(".card-info") ===
            existingForm.closest(".card-info")
          ) {
            return;
          }
        }

        existingForm.parentElement.style.transform = "none";
        existingForm.parentElement.classList.add("d-none");
        existingForm.remove();
      }

      const container = event.currentTarget.closest(".card-info");
      const editor = container.querySelector(".memorial-card-editor");

      const formContainer = document.createElement("div");
      formContainer.innerHTML = formHtml;

      const form = formContainer.querySelector("form");
      registerFormSubmitProtection(form);

      const { deceasedFirstName, editSlug } = event.currentTarget.dataset;

      form.querySelector(".deceased-name").innerText = deceasedFirstName;
      form.querySelector(".edit-slug").value = editSlug;

      const selectField = form.querySelector("select");
      const otherFieldContainer = form.querySelector(".relationship-other");

      if (selectField && otherFieldContainer) {
        const otherField = otherFieldContainer.querySelector("input");
        const { relationship, relationshipOther } = event.currentTarget.dataset;

        if (relationship && !(props.relationshipEditSlug === editSlug)) {
          const optionToSelect = form.querySelector(`option[value="${relationship}"]`);
          if (optionToSelect) {
            optionToSelect.selected = true;
          }

          if (relationship === "other") {
            otherField.value = relationshipOther;
            otherFieldContainer.classList.remove("d-none");
          }
        }

        if (props.relationshipEditSlug === editSlug) {
          if (form.querySelector('option[value="other"]').selected) {
            otherFieldContainer.classList.remove("d-none");
          }
        }

        selectField.addEventListener("change", () => {
          if (selectField.selectedOptions.length) {
            const selectedOption = selectField.selectedOptions[0];
            if (selectedOption.value === "other") {
              otherFieldContainer.classList.remove("d-none");
            } else {
              otherField.value = "";
              otherFieldContainer.classList.add("d-none");
            }
          }
        });
      }

      const aliasStrGroup = form.querySelector(".alias-str-group");
      if (aliasStrGroup) {
        const aliasInput = aliasStrGroup.querySelector('input[type="text"]');
        aliasInput.placeholder = `Ex. The ${props.userLastName} Family`;

        if (event.currentTarget.dataset.managerAlias) {
          aliasInput.value = event.currentTarget.dataset.managerAlias;
          form.querySelectorAll(".clear-button").forEach((b) => {
            b.classList.remove("d-none");
          });
        }
      }

      form.querySelectorAll(".cancel-button").forEach((cancelButton) => {
        cancelButton.addEventListener("click", () => {
          editor.style.transform = "none";
          setTimeout(() => {
            editor.removeChild(form);
            editor.classList.add("d-none");
          }, 250);
        });
      });

      editor.appendChild(form);
      editor.classList.remove("d-none");

      editor.style.bottom = `-${editor.offsetHeight}px`;
      editor.style.transform = `translateY(-${editor.offsetHeight}px)`;
    };

    document.querySelectorAll(".memorial-relationship-editable").forEach((link) => {
      link.addEventListener("click", (event) => {
        openEditHandler(event, props.relationshipFormId, props.relationshipFormHtml);
      });
    });

    if (props.relationshipEditSlug) {
      // "Click" to open the form on the failed edit form for this.
      const editRelationshipLink = document.querySelector(
        `.memorial-relationship-editable[data-edit-slug="${props.relationshipEditSlug}"`,
      );
      if (editRelationshipLink) {
        openEditHandler(
          { currentTarget: editRelationshipLink, target: editRelationshipLink },
          props.relationshipFormId,
          props.relationshipFormHtml,
        );
      }
    }

    document.querySelectorAll(".manager-alias-editable").forEach((link) => {
      link.addEventListener("click", (event) => {
        openEditHandler(event, props.managerAliasFormId, props.managerAliasFormHtml);
      });
    });

    if (props.managerAliasEditSlug) {
      // "Click" to open the form on the failed edit form for this.
      const editManagerAliasLink = document.querySelector(
        `.manager-alias-editable[data-edit-slug="${props.managerAliasEditSlug}"]`,
      );
      if (editManagerAliasLink) {
        openEditHandler(
          { currentTarget: editManagerAliasLink, target: editManagerAliasLink },
          props.managerAliasFormId,
          props.managerAliasFormHtml,
        );
      }
    }
  }

  if (props.isEditing) {
    document
      .querySelector(".member-profile-upload-photo-container")
      .addEventListener("click", () => {
        const cropperProps = {
          disableBlur: true,
          photoInput: document.querySelector(`#${props.photoCropper.photoInputId}`),
          xInput: document.querySelector(`#${props.photoCropper.xInputId}`),
          yInput: document.querySelector(`#${props.photoCropper.yInputId}`),
          heightInput: document.querySelector(`#${props.photoCropper.heightInputId}`),
          widthInput: document.querySelector(`#${props.photoCropper.widthInputId}`),
          imagePreviewSelector: ".member-profile-picture",
          imgPlaceholder: props.photoCropper.imgPlaceholder,
          cropperOptions: {
            minCropBoxWidth: 300,
            minCropBoxHeight: 300,
            aspectRatio: 1.0,
            viewMode: 1,
          },
        };

        initPhotoCropper(cropperProps);
        document.querySelector(`#${props.photoCropper.photoInputId}`).click();
      });

    const removePhotoLink = document.querySelector(".remove-photo-link");
    removePhotoLink.addEventListener("click", () => {
      const photoInput = document.querySelector(`#${props.photoCropper.photoInputId}`);
      if (photoInput && photoInput.value) {
        photoInput.value = null;
      } else {
        const removePhotoInput = document.querySelector(
          `#${props.photoCropper.removePhotoInputId}`,
        );
        removePhotoInput.value = 1;
      }
      const placeholder = document.querySelector(".member-profile-picture");
      placeholder.setAttribute("src", props.photoCropper.imgPlaceholder);
      removePhotoLink.classList.add("d-none");
    });

    initGoogleAutocomplete({
      element: document.getElementById(props.publicProfileLocationStrId),
      options: {
        types: ["(cities)"],
      },
    });
  }
}

/**
 *
 * Intialize the choices for the final wishes form.
 *
 * @param {HTMLElement} container - the container of the choices
 * @param {HTMLElement} input - the input that holds the choice selection value
 * @param {Function} [selectionCallback] - a callback function to run after a choice is made
 */
function initFinalWishChoices(container, input, selectionCallback = null) {
  const choices = container.querySelectorAll(".final-wish-choice");
  const initialChoice = container
    .querySelector(".final-wish-choices")
    .getAttribute("data-initial-value");
  choices.forEach((choice) => {
    const description = choice.querySelector(".description");
    if (choice.getAttribute("data-value") === initialChoice) {
      choice.classList.add("selected");

      if (description) {
        // The below "padding" is because on some browsers the animation timing messes
        // up the padding if we're showing a box expanded on pageload.
        const padding = 40 - description.getBoundingClientRect().height;
        description.style.height = `${description.scrollHeight + padding}px`;
      }

      if (selectionCallback) {
        selectionCallback(choice);
      }
    }

    choice.querySelector(".title").addEventListener("click", () => {
      if (choice.classList.contains("selected")) {
        input.value = "0";
        choice.classList.remove("selected");
        if (description) {
          description.style.height = 0;
        }
      } else {
        container
          .querySelectorAll(".final-wish-choice.selected")
          .forEach((selectedChoice) => {
            selectedChoice.classList.remove("selected");
            const selectedDescription = selectedChoice.querySelector(".description");
            if (selectedDescription) {
              selectedDescription.style.height = 0;
            }
          });

        input.value = choice.getAttribute("data-value");
        choice.classList.add("selected");

        if (description) {
          description.style.height = `${description.scrollHeight + 40}px`; // The padding is added as part of the transition; so it's not "here" yet.
        }

        if (selectionCallback) {
          selectionCallback(choice);
        }
      }
    });
  });
}

/**
 * Intialize the final wishes form.
 *
 * @param {object} props - the props for the final wishes form
 */
function initFinalWishesForms(props) {
  const removeDOBLink = document.querySelector(".remove-dob-link");
  const dobInputGroup = document.querySelectorAll(".date-of-birth-input-group select");

  if (removeDOBLink && dobInputGroup) {
    removeDOBLink.addEventListener("click", () => {
      dobInputGroup.forEach((input) => {
        input.selectedIndex = null;
      });

      removeDOBLink.classList.add("d-none");
      removeDOBLink.classList.remove("d-block");
    });

    dobInputGroup.forEach((input) => {
      input.addEventListener("change", () => {
        removeDOBLink.classList.remove("d-none");
        removeDOBLink.classList.add("d-block");
      });
    });
  }

  const burialTypeInput = document.querySelector(`#${props.burialTypeInputId}`);
  initFinalWishChoices(document.querySelector(".burial"), burialTypeInput);

  const eventTypeInput = document.querySelector(`#${props.eventTypeInputId}`);
  const eventContainer = document.querySelector(".event");
  const eventDetailsContainer = eventContainer.querySelector(".event-details");
  initFinalWishChoices(eventContainer, eventTypeInput, (choice) => {
    if (eventDetailsContainer) {
      if (choice.getAttribute("data-value") === `${props.FINAL_WISHES_NO_EVENT}`) {
        eventDetailsContainer.classList.add("d-none");
      } else {
        eventDetailsContainer.classList.remove("d-none");
      }
    }
  });

  initGoogleAutocomplete({
    element: document.getElementById(props.publicProfileLocationStrId),
    options: {
      types: ["(cities)"],
    },
  });

  initGoogleAutocomplete({
    element: document.getElementById(props.eventLocationStrId),
    options: {
      types: ["establishment"],
    },
  });
}

/**
 * Run the code for the final wishes page.
 *
 * @param {object} props - the props for the final wishes page
 */
export function initUserFinalWishes(props) {
  if (props.isEditing) {
    initFinalWishesForms(props);
  } else if (props.isEditable) {
    initClipboard();
    const fbShare = document.querySelector(".btn-facebook-share");
    if (fbShare) {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: config.facebookAppId,
          autoLogAppEvents: true,
          xfbml: true,
          version: config.facebookApiVersion,
        });
        fbShare.addEventListener("click", () => {
          if (config.debug) {
            console.log(
              `Would share the following link to Facebook: ${props.facebookShare.href}`,
            );
          }
          window.FB.ui(props.facebookShare);
        });
      };
    }
  }
}

/**
 * Run the code for the final wishes landing page.
 *
 * @param {object} props - the props for the final wishes landing page
 */
export function initFinalWishesLandingPage(props) {
  initFinalWishesForms(props);
}

/**
 * Run the code for the give premium page.
 */
export function initGivePremium() {
  initClipboard();

  const profileContent = document.querySelector(".profile-content");
  if (profileContent) {
    scrollToElement(profileContent, window.isOnMobile ? 200 : 0);
  }
}

/**
 * Run the code for the user deactivate page.
 *
 * @param {object} props - the props for the user deactivate page
 */
export function initUserDeactivate(props) {
  initPublicAutoScroll(props);
}
